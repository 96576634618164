<template>
  <el-tag :type="props.extend" effect="dark">
    {{ props.label }}
  </el-tag>
</template>

<script setup lang="ts">
const props = defineProps({
  extend: {
    type: String,
    default: "primary",
  },
  label: {
    type: String,
    required: true,
  },
});
</script>

<style scoped>
</style>